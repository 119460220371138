import logo from "../../assets/img/logo.svg";
import iconConnexion from "../../assets/img/login_icon.svg";
import slider from "../../assets/img/first-page-image.jpg";
import slider2 from "../../assets/img/img-children.jpg";
import slider3 from "../../assets/img/children2.jpg";
import gameboy from "../../assets/img/gameboy.jpg";
import viewAll from "../../assets/img/view-all-arrow.svg";
import childSlider1 from "../../assets/img/image-slider01.jpg";
import childSlider2 from "../../assets/img/image-slider02.jpg";
import childSlider3 from "../../assets/img/image-slider03.jpg";
import sticky from "../../assets/img/sticky-note.svg";
import pin from "../../assets/img/pin.png";

import logoFooter from "../../assets/img/logo-footer.svg";
import feedImage from "../../assets/img/img-children.jpg";
import imgSignin from "../../assets/img/sign-in-kids.png";
import registerKids from "../../assets/img/register-kids.png";
import editIcon from "../../assets/img/editIcon.png";

import chimie from "../../assets/img/Chimie.gif";
import geographie from "../../assets/img/Geography.gif";
import physique from "../../assets/img/Physics.gif";
import english from "../../assets/img/English.gif";
import frensh from "../../assets/img/French.gif";
import history from "../../assets/img/History.gif";
import maths from "../../assets/img/Maths.gif";
import sciences from "../../assets/img/Sciences.gif";
import arabic from "../../assets/img/Arabic.gif";
import iconUser from "../../assets/img/user.png";
import iconGroup from "../../assets/img/group.png";
import Group from "../../assets/img/chat_bot.svg";
import NewsFeed from "../../assets/img/shape-news-feed.svg";
import ArrowNews from "../../assets/img/news_arrow.svg";
import Greencheck from "../../assets/img/tick.svg";
import cancel from "../../assets/img/cancel.svg";
import Arrowback from "../../assets/img/arrow-back.svg";
import iconClose from "../../assets/img/close-calc-icon.png";
import ArrowRight from "../../assets/img/did-you-know-arrow-right.svg";
import Dn from "../../assets/img/dn.svg";
import Trash from "../../assets/img/trash.svg";
import Eye from "../../assets/img/eye.svg";
import loadingci from "../../assets/img/loadingci.svg";
import iconMale from "../../assets/img/iconMale.svg";
import iconFemale from "../../assets/img/iconFemale.svg";
import schoolSlider1 from "../../assets/img/Caroussels/Ecoles/1.png";
import schoolSlider2 from "../../assets/img/Caroussels/Ecoles/2.png";
import schoolSlider3 from "../../assets/img/Caroussels/Ecoles/3.png";
import schoolSlider4 from "../../assets/img/Caroussels/Ecoles/4.png";
import schoolSlider5 from "../../assets/img/Caroussels/Ecoles/5.png";
import schoolSlider6 from "../../assets/img/Caroussels/Ecoles/6.png";
import category from "../../assets/img/category.png";
import provider from "../../assets/img/provider.png";
import product from "../../assets/img/product.png";
import journalicon from "../../assets/img/cj.svg";
import avatar from "../../assets/img/col9.jpg";
import zoomiconG from "../../assets/img/video-camera_g.png";
import zoomicon from "../../assets/img/video-camera.png";

import iconExerciceAdd from "../../assets/img/iconExerciceAdd.png";
import default_school_logo from "../../assets/img/group_2.jpg";
import logo_ministere_education from "../../assets/img/logo_ministere_education.svg";
import logo_ministere_education_2021 from "../../assets/img/logo_ministere_education_2021.jpg";
import logo_ministere_education_2024 from "../../assets/img/logo_ministere_education_2024.jpeg";
import iconFileGenerating from "../../assets/img/fileGenerating.gif";
import default_user from "../../assets/img/default_user.png";

import iconPdf from "../../assets/img/pdf_icon.png";
import iconWord2 from "../../assets/img/word_icon.png";
import iconExcel from "../../assets/img/excel_icon.png";
import iconPpt from "../../assets/img/powerpoint_icon.png";
import iconTxt from "../../assets/img/txt_icon.png";
import iconOtherTypeFile from "../../assets/img/other_file.png";
import iconExport from "../../assets/img/iconExport.svg";
import buttetin_format_10 from "../../assets/img/buttetin_format_10.png";
import buttetin_format_11 from "../../assets/img/buttetin_format_11.png";
import noImage from "../../assets/img/no_image.png"
import default_cantine_menue_image from "../../assets/img/default_cantine_menue_image.png"
import iconCantineYellow from "../../assets/img/iconCantineYellow.svg";
import iconCantineMenuYellow from "../../assets/img/iconCantineMenuYellow.svg";

export const images = {
  buttetin_format_10,
  buttetin_format_11,
  iconExport,
  default_cantine_menue_image,
  logo,
  noImage,
  logo_ministere_education,
  logo_ministere_education_2021,
  logo_ministere_education_2024,
  default_school_logo,
  category,
  iconFileGenerating,
  default_user,
  zoomicon,
  zoomiconG,
  provider,
  product,
  ArrowRight,
  journalicon,
  sticky,
  pin,
  avatar,
  iconConnexion,
  slider,
  slider2,
  slider3,
  Dn,
  iconExerciceAdd,
  gameboy,
  viewAll,
  childSlider1,
  childSlider2,
  childSlider3,
  logoFooter,
  feedImage,
  imgSignin,
  registerKids,
  editIcon,
  chimie,
  Trash,
  Eye,
  cancel,
  Greencheck,
  loadingci,
  geographie,
  physique,
  english,
  frensh,
  history,
  maths,
  sciences,
  arabic,
  iconUser,
  iconGroup,
  Group,
  NewsFeed,
  ArrowNews,
  Arrowback,
  iconClose,
  iconMale,
  iconFemale,
  schoolSlider1,
  schoolSlider2,
  schoolSlider3,
  schoolSlider4,
  schoolSlider5,
  schoolSlider6,
  iconPdf,
  iconWord2,
  iconExcel,
  iconPpt,
  iconTxt,
  iconOtherTypeFile,
  iconCantineYellow,
  iconCantineMenuYellow,
};

export const messages = {
  button: "REGISTER",
  labelNom: "Prénom"
};

export const form = {
  nom: {
    required: true,
    type: "string",
    label: messages.labelNom,
    name: "nom"
  },
  prenom: {
    required: true,
    type: "string",
    label: messages.labelNom,
    name: "prenom"
  }
};
